import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Auth0Provider } from "@auth0/auth0-react";
import history from "./utils/history";
import { getConfig } from "./config";
import AuthConfigProvider from "./context/AuthConfigProvider";
import ReactDOM from "react-dom";
import Error from "./pages/error/general";
import './i18n'; // Import the i18n configuration

const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};

const runAfterGetConfig = async () => {
    try {
      // Please see https://auth0.github.io/auth0-react/interfaces/Auth0ProviderOptions.html
      // for a full list of the available properties on the provider
      const config = await getConfig();

      const providerConfig = {
        domain: config.configJson.domain,
        clientId: config.configJson.client_id,
        onRedirectCallback,
        authorizationParams: {
          redirect_uri: window.location.origin,
          ...(config.configJson.audience ? { audience: config.configJson.audience } : null),
        },
      };
      const root = createRoot(document.getElementById('root'));
      root.render(
        <Auth0Provider
          {...providerConfig}
          useCookiesForTransactions={true}
          useRefreshTokens={true}
          cacheLocation={'localstorage'}
        >
        <AuthConfigProvider authConfig={config}>
            <Suspense fallback={<div></div>}>
              <App />
            </Suspense>
          </AuthConfigProvider>
        </Auth0Provider>,
      );

      // If you want your app to work offline and load faster, you can change
      // unregister() to register() below. Note this comes with some pitfalls.
      // Learn more about service workers: https://bit.ly/CRA-PWA
      serviceWorker.unregister();
    } catch (error) {
      console.error(error);
      ReactDOM.render(
        <Error error={error.message}/>,
        document.getElementById("root")
      )
    }
}
runAfterGetConfig()